@import '~styles/mixins';

.headerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;
  height: 96px;
  background-color: $black;

  @include b-d(md) {
    height: 80px;
  }
}

.header {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  background-color: $black;

  .navMenu {
    @include b-d(lg) {
      display: none;
    }
  }
}
