html,
body {
  -webkit-overflow-scrolling: touch;

  // Mozilla Firefox
  scrollbar-width: thin;
  scrollbar-color: $primary transparent;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Styled scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 5px;

  &:hover {
    background: darken($primary, 10);
  }
}

::selection {
  background: rgba($primary, 0.3);
}

[type='search']::-webkit-search-decoration,
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-results-button,
[type='search']::-webkit-search-results-decoration {
  appearance: none;
}

a {
  transition: color 200ms;
}

.js-off-canvas-overlay {
  -webkit-tap-highlight-color: transparent;
}

img {
  max-width: 100%;
}

svg {
  display: block;
}
