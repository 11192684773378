.svgPreview {
  min-height: 50vh;
  padding: 80px;
  background-color: #555;

  .table {
    margin: 0 auto;
    box-shadow: 4px 4px 12px rgba(#000, 0.1);
  }
}
