.container {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .container-fluid;

  max-width: 1240px;

  &-fluid {
    padding: 0 40px;
    margin: auto;
    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }
  }
}
