.svgDefs {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -999;
  width: 0;
  height: 0;
  pointer-events: none;
  visibility: hidden;
  user-select: none;
}
